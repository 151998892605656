body, html {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.91111rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f4f4f4;
  color: #333; }

.navbar {
  height: 80px !important;
  font-size: 1rem;
  background-color: #fff !important; }
  .navbar .cart-badge {
    padding: 3px;
    background-color: #942909;
    color: #fff;
    font-weight: 800;
    font-size: 0.5rem;
    border-radius: 5px; }

#top-bar {
  background-color: #c4c3fc;
  color: #fff;
  font-weight: 700;
  font-size: 1rem; }
  #top-bar .social a {
    margin-right: 0.5rem; }

@media (min-width: 980px) {
  .navbar {
    height: 0px; } }

.card-link {
  text-decoration: none; }
  .card-link p.lead {
    font-weight: 400 !important;
    font-style: normal !important;
    color: #333; }
    .card-link p.lead:hover {
      color: #c95a5a;
      text-decoration: underline; }
  .card-link p.black-10 {
    font-weight: 600 !important;
    font-size: 1.2rem;
    color: #333; }

.product-img {
  width: 100%;
  height: 250px;
  max-height: 250px; }

.empty-cart {
  background-color: #fff;
  margin: 5px 0px !important;
  padding: 40px 40px !important; }
  .empty-cart .card-img {
    height: 100px;
    width: 100px;
    float: right; }
  .empty-cart .card-title {
    font-size: 2rem;
    color: #333; }
  .empty-cart .card-text {
    font-size: 1rem; }

.product-details {
  background-color: #fff;
  padding: 50px; }
  .product-details .badge-pill {
    padding: 5px; }

.hero {
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 50px; }
  .hero p {
    font-size: 1.2rem; }
  .hero .hero-img {
    height: 350px !important;
    width: 100% !important; }

#main-footer {
  background-color: #acacac; }
